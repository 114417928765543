<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb />
    </div>
    <section class="search__container">
      <common-search :fields="fields" @search="search"></common-search>
    </section>
    <section class="table__container">
      <a-table
        :data-source="tblData"
        :columns="tblColumns"
        :loading="loading"
        :pagination="pagination"
        rowKey="taskId"
        @change="onPage"
      >
        <template slot="flowStartTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="taskArrivalTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="onExamine(record)">审核</a>
        </template>
      </a-table>
    </section>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { fetchProcessTypeList, fetchTaskList } from "@/services/TaskService";
import { pageDetailMixins } from "@/components/pageDetailMixin";
import { tsFormat } from "@/components/DateUtils";

const tblColumns = [
  {
    title: "任务标题",
    dataIndex: "taskTitle",
    width: "20%"
  },
  {
    title: "流程类型",
    dataIndex: "taskTypeValue",
    width: "15%"
  },
  {
    title: "提交机构",
    dataIndex: "corporationName",
    width: "15%"
  },
  {
    title: "创建时间",
    dataIndex: "flowStartTime",
    scopedSlots: { customRender: "flowStartTime" },
    width: "20%"
  },
  {
    title: "到达时间",
    dataIndex: "taskArrivalTime",
    scopedSlots: { customRender: "taskArrivalTime" },
    width: "20%"
  },
  {
    title: "操作",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];
export default {
  name: "ToDoTask",
  components: {
    Breadcrumb
  },
  mixins: [pageDetailMixins],
  data() {
    return {
      fields: [
        {
          label: "任务标题",
          key: "taskTitle",
          component: "j-input"
        },
        {
          label: "流程类型",
          key: "taskType",
          component: "j-select",
          getOptions: () => {
            return this.loadProcessTypeList();
          }
        },
        {
          label: "创建时间",
          key: "queryStartTime,queryEndTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        }
      ],
      form: {},
      tblData: [],
      tblColumns,
      loading: false,
      pagination: {
        showQuickJumper: true
      },
      typeList: [],
      tsFormat
    };
  },
  activated() {
    this.loadList();
  },
  methods: {
    // 加载列表
    loadList() {
      this.loading = true;
      const params = {
        taskStatus: "UnProcessed",
        flowStatus: "",
        taskTitle: this.form.taskTitle ? this.form.taskTitle : "",
        queryStartTime: this.form.queryStartTime
          ? this.form.queryStartTime + " 00:00:00"
          : "",
        queryEndTime: this.form.queryEndTime
          ? this.form.queryEndTime + " 23:59:59"
          : "",
        taskType: this.form.taskType || "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      fetchTaskList(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            const records = resp.data.data.records;
            const data = resp.data.data;
            const pagination = { ...this.pagination };
            pagination.current = data.pageNum;
            pagination.total = data.total;
            pagination.pageSize = data.pageSize;
            pagination.showTotal = function(total, range) {
              return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
            };
            this.pagination = pagination;
            this.tblData = records;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 加载流程类型列表
    async loadProcessTypeList() {
      const resp = await fetchProcessTypeList();
      if (resp.data.code === "SUCCESS") {
        const data = resp.data.data;
        this.typeList = [];
        if (data && data.length > 0) {
          data.forEach(item => {
            let obj = {};
            for (const itemKey in item) {
              obj = {
                label: item[itemKey],
                value: itemKey
              };
            }
            this.typeList.push(obj);
          });
          this.typeList.unshift({ label: "全部", value: "" });
        }
      }
      return this.typeList;
    },
    // 搜索
    search(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    // 审核
    onExamine(record) {
      if (record.taskTypeValue === "机构准入") {
        this.$router.push({
          name: "ExamineDetail",
          params: { applicationId: record.relId, type: "detail" }
        });
      } else if (record.taskTypeValue === "委托经营三方协议") {
        if (record.taskType === "Tripartite_Agreement_Upload") {
          this.$router.push({
            name: "UploadTripartiteAgreement",
            params: { type: "upload", contractNo: record.relId }
          });
        } else {
          this.$router.push({
            name: "ModifyTripartiteAgreement",
            params: { type: "modify", contractNo: record.relId }
          });
        }
      } else if (record.taskTypeValue === "价值包申报") {
        this.$router.push({
          name: "ValueExaminedDetail",
          params: {
            id: record.relId,
            action: "审核"
          }
        });
      } else if (record.taskTypeValue === "试骑活动") {
        this.$router.push({
          name: "ExaminedActivityList",
          params: {
            id: record.relId,
            action: "审核"
          }
        });
      } else if (record.taskTypeValue === "租赁活动") {
        this.$router.push({
          name: "ExaminedLeaseActivity",
          params: {
            id: record.relId,
            action: "审核"
          }
        });
      } else if (record.taskTypeValue === "订单审核") {
        if (record.taskTitle.substring(0, 4) === "采购订单") {
          this.$router.push({
            name: "PurchaseOrderExamineOperation",
            params: { orderSn: record.relId, type: "examine" }
          });
        } else {
          this.$router.push({
            name: "OperationReturnedExamine",
            params: {
              orderSn: record.relId,
              type: "examine",
              status: "approval"
            }
          });
        }
      } else if (record.taskTypeValue === "付款审核") {
        this.$router.push({
          name: "PayOrderDetail",
          params: { orderSn: record.relId, type: "examine" }
        });
      } else if (record.taskTypeValue === "退款审核") {
        this.$router.push({
          name: "RefundReviewExamine",
          params: { orderSn: record.relId, type: "examine" }
        });
      }
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item {
  margin-right: 20px;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: left !important;
  min-width: 80px !important;
}
</style>
